import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDKSwbGUQfgp4gKmAPTLXaASzbmsvlddcM',
  authDomain: 'eshop-ccbda.firebaseapp.com',
  projectId: 'eshop-ccbda',
  storageBucket: 'eshop-ccbda.appspot.com',
  messagingSenderId: '408817005035',
  appId: '1:408817005035:web:eeadcf198abcfa27ffa9ae',
};

export const app = initializeApp(firebaseConfig);
